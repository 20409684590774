import "./App.css";

import React from "react";
import { Route, Switch } from "react-router-dom";
// import Home from "./Home";
// import Detail from "./Detail";
// import Listing from "./Listing";
import Landing from "./Landing/Landing";
import Payment from "./Payment";

function App() {
  // Can use Home for all, Listing and Detail are just for testing
  // for `/payment`, we will show blank white screen
  return (
    <Switch>
      <Route exact path="/" component={Landing} />
      <Route exact path="/home" component={Landing} />
      <Route exact path="/mynjoi" component={Landing} />
      <Route exact path="/notifications" component={Landing} />
      <Route exact path="/settings" component={Landing} />
      <Route exact path="/payment" component={Payment} />

      <Route path="/listing/:id" component={Landing} />
      <Route path="/movie/:id" component={Landing} />
      <Route path="/channel/:id" component={Landing} />
      <Route path="/bundle/:id" component={Landing} />
      <Route path="/package/:id" component={Landing} />
      <Route path="/promotion/:id" component={Landing} />
      <Route path="/video/:id" component={Landing} />
      <Route exact path="/njoihd" component={Landing} />

      <Route exact path="/transactions" component={Landing} />
      <Route exact path="/voucher" component={Landing} />
      
      <Route exact path="/favorite" component={Landing} />

      <Route exact path="/rewards" component={Landing} />
      <Route exact path="/giftbox" component={Landing} />
      <Route exact path="/topup" component={Landing} />
    </Switch>
  );
}

export default App;
