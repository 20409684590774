import React from "react";
import "./Landing.css";
import Google from "../images/google.svg";
import Apple from "../images/Apple.svg";
import UniversalImage from "../images/NJOI-Logo.png";
import Huawei from "../images/huaweiappbadge.png";

const Landing = (props) => {
  const clickHandler = (evName, itemList, itemName) => {
    try {
      window.dataLayer.push({
        event: evName,
        item_list: itemList,
        item_name: itemName,
      });
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <React.Fragment>
      <div className="container-mod">
        <div className="Universal-Link-Container">
          <div className="Universal-Link-Logo-Container">
            <img
              className="Universal-Link-Logo"
              src={UniversalImage}
              alt="My NJOI-Logo"
            />
          </div>

          <div className="Universal-Link-Content">
            <div className="Universal-Link-Title">
              My NJOI
            </div>
            <div className="Universal_Link-Description">
              Semuanya senang dengan My NJOI! Dengan aplikasi My NJOI, anda boleh mengurus semua pembelian dengan serta-merta. Muat turun sekarang!
            </div>
            <div className="Universal_Link-Description"><i>Everything’s easier with My NJOI! With My NJOI app, you can manage all purchases instantly. Download it now!</i></div>
            <div className="Universal-Link-Social-Apps">
              <div className="Universal-Link-Google-Social-App">
                <a href="https://play.google.com/store/apps/details?id=my.com.astro.njoihd&amp;hl=en">
                  <img
                    onClick={() =>
                      clickHandler(
                        "clickCTAGooglePlay",
                        "NJOI HD",
                        "Google Play"
                      )
                    }
                    className="google-social-App"
                    src={Google}
                    alt="Google-PlayStore"
                  />
                </a>
              </div>
              <div className="Universal-Link-Apple-Social-App">
                <a href="https://apps.apple.com/my/app/my-njoi/id1556398412">
                  <img
                    onClick={() =>
                      clickHandler("clickCTAAppStore", "NJOI HD", "App Store")
                    }
                    className="apple-social-App"
                    src={Apple}
                    alt="Apple-Store"
                  />
                </a>
              </div>
              <div className="Universal-Link-AppGallery-Social-App">
                <a href="https://appgallery.huawei.com/#/app/C104310663?appId=C104310663&source=appshare&subsource=C104310663">
                  <img
                    onClick={() =>
                      clickHandler(
                        "clickCTAAppGallery",
                        "NJOI HD",
                        "App Gallery"
                      )
                    }
                    className="apple-social-App"
                    src={Huawei}
                    alt="App Gallery"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Landing;
