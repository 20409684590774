import React, { Component } from "react";
 
class Payment extends Component {
  render() {
    return (
      <div class="fold-7"></div>
    );
  }
}
 
export default Payment;